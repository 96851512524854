import React, { Fragment } from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import { kebabCase, upperFirst } from "lodash";

class TagsList extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allKdccmsNews;
    let tags = {};
    posts.forEach(edge => {
      const pTags =
        edge.node.tags.split(",").map(item => item.trim().toLowerCase()) || [];

      pTags.forEach(tag => {
        if (tag === "") return;
        if (!tags[tag]) tags[tag] = 0;
        tags[tag] += 1;
      });
    });

    if (!tags || tags.length === 0) {
      return null;
    }

    return (
      <div className="mb-7">
        <h3 className="h5 mb-3">Tags</h3>

        <div className="border rounded p-4">
          <ul className="list-unstyled">
            {Object.keys(tags).map((tag, ctr) => {
              const count = tags[tag];
              return (
                <Fragment key={tag}>
                  <li className="py-1">
                    <Link
                      to={`/tags/${kebabCase(tag)}/`}
                      className="text-secondary"
                    >
                      {upperFirst(tag)} ({count})
                    </Link>
                  </li>
                  {ctr < tags.length - 1 && (
                    <li className="dropdown-divider"></li>
                  )}
                </Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query TagsListQuery {
        allKdccmsNews(limit: 1000) {
          edges {
            node {
              tags
            }
          }
        }
      }
    `}
    render={(data, count) => <TagsList data={data} count={count} />}
  />
);
