import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const ImageGallery = ({ images, alt }) => {
  return (
    <SimpleReactLightbox>
      <SRLWrapper>
        <div className="image-grid">
          {images &&
            images.map(img => (
              <a
                className="image-item"
                key={img.childImageSharp.fluid.src}
                href={img.childImageSharp.fluid.src}
                data-attribute="SRL"
              >
                <img
                  className="mb-3 rounded"
                  src={img.childImageSharp.fluid.src}
                  alt={alt}
                />
              </a>
            ))}
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  );
};

export default ImageGallery;
