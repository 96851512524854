import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroBanner from "../components/hero-banner";
import Content, { HTMLContent } from "../components/Content";
import TagsList from "../components/tags-list";
import ImageGallery from "../components/ImageGallery";

export const NewsPostTemplate = props => {
  const [url, setUrl] = useState("");
  const {
    content,
    contentComponent,
    title,
    publishDate,
    description,
    bannerImage,
    images,
    desktopWide,
    desktop,
    tablet,
    mobile,
    mobileXS
  } = props;
  const PostContent = contentComponent || Content;
  const tags = props.tags.split(",").map(item => item.trim().toLowerCase());

  useEffect(() => {
    setUrl(window.location.href);
  }, [url]);

  return (
    <Fragment>
      {bannerImage ? (
        <HeroBanner
          desktopWide={bannerImage.bgDesktopWide.fluid.src}
          desktop={bannerImage.bgDesktop.fluid.src}
          tablet={bannerImage.bgTablet.fluid.src}
          mobile={bannerImage.bgMobile.fluid.src}
          mobileXS={bannerImage.bgMobileXS.fluid.src}
        >
          <div className="mb-7">
            <h1 className="display-4 font-size-48--md-down text-white mb-4">
              {title}
            </h1>
          </div>
        </HeroBanner>
      ) : (
        <div className="gradient-half-blue-v1">
          <HeroBanner
            desktopWide={desktopWide}
            desktop={desktop}
            tablet={tablet}
            mobile={mobile}
            mobileXS={mobileXS}
          >
            <h1 className="text-white">{title}</h1>
          </HeroBanner>
        </div>
      )}

      <div className="container space-1 space-2--lg">
        <div className="row">
          <div className="col-lg-9 mb-9 mb-lg-0">
            <div className="mb-9">
              <ul className="list-inline text-secondary mb-9">
                <li className="list-inline-item mr-4">
                  <span className="far fa-calendar-alt mr-2"></span>
                  {publishDate}
                </li>
                <li className="list-inline-item mr-4">
                  <span className="far fa-folder-open mr-2"></span>
                  <Link to="/news/">News</Link>
                </li>
              </ul>
              <p>{description}</p>
              <PostContent content={content} />

              {images && images.length > 0 && (
                <div className="space-2">
                  <ImageGallery images={images} alt={title} />
                </div>
              )}

              <div className="media mb-5">
                <div className="min-width-35">
                  <h4 className="h6">Share</h4>
                </div>
                <div className="media-body">
                  <ul className="list-inline mb-0">
                    <li className="list-inline-item">
                      <a
                        className="u-icon u-icon--secondary u-icon--sm"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-facebook-f u-icon__inner"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="u-icon u-icon--secondary u-icon--sm twitter-share-button"
                        href={`https://twitter.com/intent/tweet?text=Checkout%20Static%20Power%20PH%20project&url=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-twitter u-icon__inner"></span>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="u-icon u-icon--secondary u-icon--sm"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="fab fa-linkedin u-icon__inner"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {tags && tags.length ? (
                <div className="mb-7">
                  {tags.map(tag => (
                    <Link
                      key={tag}
                      to={`/tags/${kebabCase(tag)}/`}
                      className="u-label u-label--dark u-label--sm mr-2 mb-2"
                    >
                      {tag}
                    </Link>
                  ))}
                </div>
              ) : null}
              <p>
                <Link to="/news/">Back to Latest News</Link>
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            {" "}
            <TagsList />{" "}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

NewsPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object
};

const NewsPost = ({ data }) => {
  const {
    kdccmsNews: post,
    site: {
      siteMetadata: { siteUrl }
    }
  } = data;
  const tags = post.tags.split(",").map(item => item.trim().toLowerCase());
  const images = [];
  for (let ctr = 1; ctr <= 10; ctr += 1) {
    if (post[`image${ctr}`]) images.push(post[`image${ctr}`]);
  }

  return (
    <Layout>
      <SEO
        title={`${post.title} | News`}
        keywords={tags}
        description={`${post.description}`}
        meta={[
          {
            property: `og:image`,
            content: `${siteUrl}${post.bannerImage.bgDesktop.fluid.src}`
          }
        ]}
      />
      <NewsPostTemplate
        content={post.body}
        contentComponent={HTMLContent}
        description={post.description}
        tags={post.tags}
        title={post.title}
        publishDate={post.publishDate}
        bannerImage={post.bannerImage}
        images={images}
        desktopWide={data.bgDesktopWide.childImageSharp.fluid.src}
        desktop={data.bgDesktop.childImageSharp.fluid.src}
        tablet={data.bgTablet.childImageSharp.fluid.src}
        mobile={data.bgMobile.childImageSharp.fluid.src}
        mobileXS={data.bgMobileXS.childImageSharp.fluid.src}
      />
    </Layout>
  );
};

NewsPost.propTypes = {
  data: PropTypes.shape({
    kdccmsNews: PropTypes.object
  })
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    kdccmsNews(id: { eq: $id }) {
      id
      body
      publishDate(formatString: "MMMM DD, YYYY")
      title
      description
      tags
      bannerImage {
        bgDesktopWide: childImageSharp {
          fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
        bgDesktop: childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
        bgTablet: childImageSharp {
          fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
        bgMobile: childImageSharp {
          fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }

        bgMobileXS: childImageSharp {
          fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image1 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image4 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image5 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image6 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image7 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image8 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image9 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image10 {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
    bgDesktopWide: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgDesktop: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgTablet: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 992, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobile: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    bgMobileXS: file(relativePath: { eq: "bg2.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
